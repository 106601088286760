import {
  FOG_60,
  OwnUpBodyMedium,
  OwnUpFloatingModal,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpModalProps,
  OwnUpSmallBody,
  OwnUpSmallBodyItalic,
  OwnUpSmallBodyMedium,
  OwnUpThemeProvider,
  PropsWithTheme,
  SLATE_100
} from '@rategravity/own-up-component-library';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ReviewStars } from './review-stars';
import { OwnUpLenderReview, OwnUpLenderReviewProfile } from './types';

const ReviewStyledModal = styled(OwnUpFloatingModal)`
  .modal-body {
    ${({ theme }: PropsWithTheme<OwnUpModalProps>) => theme.breakpoints.up('sm')} {
      min-width: 554px;
    }
  }
`;

const StyledReviewDate = styled(OwnUpSmallBodyItalic)`
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  color: ${SLATE_100};
`;

const Separator = styled.div`
  display: flex;
  margin: 24px 0;
  border-bottom: 1px solid ${FOG_60};
  width: 100%;
`;

export interface ReviewModalProps {
  lenderReviews: OwnUpLenderReviewProfile;
  lenderName?: string;
  currentPage: number;
  isOpen: boolean;
  openReviewModal: (isOpen: boolean) => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  reviewsPerPage?: number;
}

export const useSortLenderReviews = (reviews: readonly OwnUpLenderReview[]) =>
  useMemo(() => {
    if (reviews.length > 0) {
      // Need to shallow copy the array because array.sort manipulate the array
      //   and this is being sourced from redux which is immutable.
      return [...reviews].sort((a: OwnUpLenderReview, b: OwnUpLenderReview) => {
        // Sort the lender reviews by descending review rating, then by review date (most recent first).
        return b.lenderRating - a.lenderRating || b.reviewDate - a.reviewDate;
      });
    }

    return [];
  }, [reviews]);

const useDivideReviewsIntoPages = (reviews: OwnUpLenderReview[], reviewsPerPage?: number) => {
  const divideReviewsIntoPages = useCallback(() => {
    const pagedReviews: Array<OwnUpLenderReview[]> = [];
    if (reviewsPerPage) {
      [...Array(Math.ceil(reviews.length / reviewsPerPage))].forEach((_review, i) => {
        pagedReviews.push(
          reviews.slice(0 + reviewsPerPage * i, reviewsPerPage + reviewsPerPage * i)
        );
      });
    }
    return pagedReviews;
  }, [reviews, reviewsPerPage]);
  return divideReviewsIntoPages();
};

export const ReviewModal = ({
  lenderReviews,
  lenderName,
  currentPage,
  isOpen,
  openReviewModal,
  reviewsPerPage = 3
}: ReviewModalProps) => {
  const onCloseModal = useCallback(() => {
    openReviewModal(false);
  }, [openReviewModal]);
  const reviewsRef = useRef<HTMLDivElement>(null);

  const sortedReviews = useSortLenderReviews(lenderReviews.reviews);

  // Only display reviews with a rating >= 4
  const reviewsToBeRendered = sortedReviews.filter(({ lenderRating }) => lenderRating >= 4);
  const reviewPages = useDivideReviewsIntoPages(reviewsToBeRendered, reviewsPerPage);

  const renderLenderReviews = useMemo(
    () =>
      reviewPages[currentPage - 1]?.map(
        ({ reviewer, reviewDate, lenderRating, lenderReview }, i) => {
          // Convert epoch timestamp to formatted string
          const reviewDateString = new Date(reviewDate).toLocaleDateString('en-us', {
            month: 'long',
            day: 'numeric'
          });

          return (
            <div key={i} style={{ marginBottom: '16px' }}>
              <OwnUpSmallBodyMedium variant="body1">{reviewer}</OwnUpSmallBodyMedium>
              <ReviewStars value={lenderRating} />
              <StyledReviewDate variant="body1">{reviewDateString}</StyledReviewDate>
              <OwnUpSmallBody variant="body1">{lenderReview}</OwnUpSmallBody>
            </div>
          );
        }
      ),
    [currentPage, reviewPages]
  );

  return (
    <OwnUpThemeProvider>
      <ReviewStyledModal title="Reviews" open={isOpen} onClose={onCloseModal}>
        <OwnUpGridContainer variant="slim">
          <OwnUpGridItem xs={12}>
            <OwnUpSmallBody variant="body1" style={{ marginBottom: '16px' }}>
              {`These reviews are from Own Up customers who’ve taken out loans with ${lenderName}.`}
            </OwnUpSmallBody>
            <ReviewStars value={lenderReviews.averageScore} />
            <OwnUpBodyMedium variant="body1">{`${lenderReviews.averageScore.toFixed(
              1
            )} / 5.0 star average`}</OwnUpBodyMedium>
            <Separator />
            <div ref={reviewsRef}>{renderLenderReviews}</div>
          </OwnUpGridItem>
        </OwnUpGridContainer>
      </ReviewStyledModal>
    </OwnUpThemeProvider>
  );
};
