import { CACTUS_100, FOG_60 } from '@rategravity/own-up-component-library';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Star100, Star25, Star50, Star75 } from '../../../images/csma/stars';

interface StarStyle {
  width: string;
  height: string;
  spacing: string;
}

const StarContainer = styled.div`
  margin: 4px 0;
  display: flex;
  height: 1.1em;
`;

const StarImage = styled.div<StarStyle>`
  margin-right: ${({ spacing }) => spacing};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

/**
 * `value` prop (default is out of 5) dictates how many stars to fill in
 * overriding the filled in and empty icon color with CSS
 * @param value A value from 0-5
 * @param style Allows adjusting the size of the star components and the margin between them
 * @param emptyFill Allows adjusting the empty fill color
 * @param fill Allows adjusting the filled fill color
 */
export const ReviewStars = ({
  value,
  style = {
    width: '12px',
    height: '12px',
    spacing: '2px'
  },
  emptyFill = FOG_60,
  fill = CACTUS_100
}: {
  value: number;
  style?: StarStyle;
  emptyFill?: string;
  fill?: string;
}) => {
  const int = Math.floor(value);
  const decimal = Number('0.' + value.toFixed(2).split('.')[1]);
  const DecimalStar = useMemo(
    () => () => {
      const createStarComponent = (c: JSX.Element) => <StarImage {...style}>{c}</StarImage>;
      if (decimal > 0.97) {
        return createStarComponent(<Star100 fill={fill} />);
      } else if (decimal > 0.67) {
        return createStarComponent(<Star75 fill={fill} emptyFill={emptyFill} />);
      } else if (decimal > 0.4) {
        return createStarComponent(<Star50 fill={fill} emptyFill={emptyFill} />);
      } else if (decimal > 0.03) {
        return createStarComponent(<Star25 fill={fill} emptyFill={emptyFill} />);
      }
      return null;
    },
    [decimal, emptyFill, fill, style]
  );

  return (
    <StarContainer>
      {value >= 1 &&
        [...Array(int)].map((_, i) => (
          <StarImage key={i} {...style}>
            <Star100 fill={fill} />
          </StarImage>
        ))}
      {value !== 0 && <DecimalStar />}
      {value < 5 &&
        [...Array((value === int ? 5 : 4) - int)].map((_, i) => (
          <StarImage key={i} {...style}>
            <Star100 fill={emptyFill} />
          </StarImage>
        ))}
    </StarContainer>
  );
};
