import { OwnUpDetailMedium, OwnUpLogoWithText } from '@rategravity/own-up-component-library';
import React, { useState } from 'react';
import { ReviewModal } from '../review-modal';
import { OwnUpLenderReviewProfile } from '../types';
import { LogoWrapper, ReviewLink } from './elements';
import { RatingBadge } from './rating-badge';

type OwnUpReviewProps = { ownUpLenderReviewProfile: OwnUpLenderReviewProfile; lenderName: string };

export const OwnUpReview = ({ ownUpLenderReviewProfile, lenderName }: OwnUpReviewProps) => {
  const [reviewModalIsOpen, setReviewModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const score = ownUpLenderReviewProfile.averageScore.toFixed(1);
  const stars = ownUpLenderReviewProfile.averageScore;

  return (
    <React.Fragment>
      <ReviewModal
        isOpen={reviewModalIsOpen}
        lenderReviews={ownUpLenderReviewProfile}
        lenderName={lenderName}
        openReviewModal={setReviewModalIsOpen}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <RatingBadge score={score} stars={stars}>
        <ReviewLink onClick={() => setReviewModalIsOpen(true)} link="#">
          <OwnUpDetailMedium variant="body1">
            {ownUpLenderReviewProfile.totalReviews} reviews
          </OwnUpDetailMedium>
        </ReviewLink>
        <LogoWrapper>
          <OwnUpLogoWithText />
        </LogoWrapper>
      </RatingBadge>
    </React.Fragment>
  );
};
