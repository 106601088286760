import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchLenderRepresentations,
  fetchLenderReview,
  fetchOULenderReview
} from '../../components/csma/reviews/api';
import {
  lenderRepresentationsConverter,
  ownUpLenderReviewProfileConverter,
  zillowLenderReviewProfileConverter
} from '../../components/csma/reviews/types';

import {
  csmaPageLoaded,
  fetchLenderRepresentationsFailed,
  fetchLenderRepresentationsSucceeded,
  fetchOwnUpLenderReviewProfileFailed,
  fetchOwnUpLenderReviewProfileNotFound,
  fetchOwnUpLenderReviewProfileSucceeded,
  fetchZillowLenderReviewProfileFailed,
  fetchZillowLenderReviewProfileNotFound,
  fetchZillowLenderReviewProfileSucceeded
} from './slice';

function* loadLenderRepresentations({
  payload: { lenderId }
}: ReturnType<typeof csmaPageLoaded>): any {
  try {
    const { success, error, json, code } = yield call(fetchLenderRepresentations, [lenderId]);
    if (success) {
      return yield put(
        fetchLenderRepresentationsSucceeded({ lenders: lenderRepresentationsConverter(json) })
      );
    }

    throw Error(`${code}: ${error}`);
  } catch (err) {
    return yield put(fetchLenderRepresentationsFailed());
  }
}

function* loadZillowLenderReviewProfile({
  payload: { lenderId }
}: ReturnType<typeof csmaPageLoaded>): any {
  try {
    const { success, error, json, code } = yield call(fetchLenderReview, lenderId);
    if (success) {
      return yield put(
        fetchZillowLenderReviewProfileSucceeded({
          zillowLenderReviewProfile: zillowLenderReviewProfileConverter(json)
        })
      );
    }
    if (code === 404) {
      return yield put(fetchZillowLenderReviewProfileNotFound());
    }
    throw Error(`${code}: ${error}`);
  } catch (err) {
    console.log(err);
    return yield put(fetchZillowLenderReviewProfileFailed());
  }
}

function* loadOwnUpLenderReviewProfile({
  payload: { lenderId }
}: ReturnType<typeof csmaPageLoaded>): any {
  // fetch OU reviews
  try {
    const {
      success: successOU,
      error: errorOU,
      json: jsonOU,
      code: codeOU
    } = yield call(fetchOULenderReview, lenderId);

    if (successOU) {
      return yield put(
        fetchOwnUpLenderReviewProfileSucceeded({
          ownUpLenderReviewProfile: ownUpLenderReviewProfileConverter(jsonOU)
        })
      );
    }
    if (codeOU === 404) {
      return yield put(fetchOwnUpLenderReviewProfileNotFound());
    }
    throw Error(`${codeOU}: ${errorOU}`);
  } catch (err) {
    console.log(err);
    return yield put(fetchOwnUpLenderReviewProfileFailed());
  }
}

export function* saga() {
  yield all([
    takeEvery(csmaPageLoaded.type, loadLenderRepresentations),
    takeEvery(csmaPageLoaded.type, loadZillowLenderReviewProfile),
    takeEvery(csmaPageLoaded.type, loadOwnUpLenderReviewProfile)
  ]);
}
