import {
  OwnUpExtraSmallHeadlineBook,
  OwnUpGridItem,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const Container = styled(OwnUpGridItem)`
  display: flex;
  flex-direction: column;
`;

export const Headline = styled(OwnUpExtraSmallHeadlineBook)`
  margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 0, 2)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 0)};
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  align-items: center;
  margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2, 0, 4)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    flex-direction: row;
    gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
    margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 0)};
  }
`;
