import { OwnUpDetail, PropsWithTheme, WHITE_100 } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const LenderCardContainer = styled.div`
  background-color: ${WHITE_100};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
`;

export const Logo = styled.img`
  display: block;
`;

export const Body = styled.div`
  margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;

  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    flex-direction: row;
    column-gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(12)}px;

    & > :first-child {
      flex-basis: 100%;
    }
  }
`;

export const LenderInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
`;

export const DetailCopy = styled(OwnUpDetail)`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0.5)}px;
`;
