import { OwnUpSmallSubheadlineMedium } from '@rategravity/own-up-component-library';
import React from 'react';
import { useSelector } from 'react-redux';
import { addFileSuffix } from '../../../modules/assets/add-file-suffix';
import { lenderDataSelector } from '../../../redux/csma/selector';
import { Lender, LenderRepresentation } from '../reviews/types';
import { Body, DetailCopy, Header, LenderCardContainer, Logo } from './elements';
import { LenderStats } from './lender-stats';
import { SuccessStories } from './success-stories';

export type LenderCardProps = Pick<
  LenderRepresentation,
  'id' | 'fundedAmountLastYear' | 'licensedStates' | 'logo' | 'nmls' | 'yearFounded'
> & {
  lenderName: string;
  ownUpLenderReviewProfile: Lender['ownUpLenderReviewProfile'];
  zillowLenderReviewProfile: Lender['zillowLenderReviewProfile'];
};

export const LenderCardImpl = ({
  fundedAmountLastYear,
  id,
  lenderName,
  licensedStates,
  logo,
  ownUpLenderReviewProfile,
  nmls,
  yearFounded,
  zillowLenderReviewProfile
}: LenderCardProps) => (
  <LenderCardContainer>
    <Header>
      <Logo
        height="48px"
        src={addFileSuffix('https://rate-quote-static.ownup.com/' + logo, '_square-400')}
        alt={`${lenderName} logo`}
      />
      <div>
        <OwnUpSmallSubheadlineMedium variant="h2">{lenderName}</OwnUpSmallSubheadlineMedium>
        <DetailCopy>NMLS: {nmls}</DetailCopy>
      </div>
    </Header>
    <Body>
      <LenderStats
        fundedAmountLastYear={fundedAmountLastYear}
        licensedStates={licensedStates}
        yearFounded={yearFounded}
      />
      <SuccessStories
        lenderId={id}
        lenderName={lenderName}
        ownUpLenderReviewProfile={ownUpLenderReviewProfile}
        zillowLenderReviewProfile={zillowLenderReviewProfile}
      />
    </Body>
  </LenderCardContainer>
);

export const LenderCard = () => {
  const {
    lenderData: { zillowLenderReviewProfile, ownUpLenderReviewProfile, lenderRepresentation }
  } = useSelector(lenderDataSelector);

  if (!lenderRepresentation) {
    return null;
  }

  const {
    id,
    fundedAmountLastYear,
    licensedStates,
    logo,
    fullName: lenderName,
    nmls,
    yearFounded
  } = lenderRepresentation;

  return (
    <LenderCardImpl
      {...{
        fundedAmountLastYear,
        id,
        lenderName,
        licensedStates,
        logo,
        ownUpLenderReviewProfile,
        nmls,
        yearFounded,
        zillowLenderReviewProfile
      }}
    />
  );
};
