import * as t from 'type-shift';

export interface ZillowLenderReviewProfile {
  profileURL: string;
  rating: number;
  totalReviews: number;
}

export const zillowLenderReviewProfileConverter = t.strict<ZillowLenderReviewProfile>({
  profileURL: t.string,
  rating: t.number,
  totalReviews: t.number
});

export interface OwnUpLenderReview {
  reviewDate: number;
  lenderReview: string;
  lenderRating: number;
  reviewer?: string;
}

const ownUpLenderReviewConverter = t.strict<OwnUpLenderReview>({
  reviewDate: t.number,
  lenderReview: t.string,
  lenderRating: t.number,
  reviewer: t.optional(t.string)
});

export interface OwnUpLenderReviewProfile {
  averageScore: number;
  totalReviews: number;
  reviews: OwnUpLenderReview[];
}

export const ownUpLenderReviewProfileConverter = t.strict<OwnUpLenderReviewProfile>({
  averageScore: t.number,
  totalReviews: t.number,
  reviews: t.array(ownUpLenderReviewConverter)
});

export interface LenderReviewLoadingStatus {
  loadingStatus: 'pending' | 'failed' | 'lenderId_not_found';
}

export interface Lender {
  zillowLenderReviewProfile: ZillowLenderReviewProfile | LenderReviewLoadingStatus;
  ownUpLenderReviewProfile: OwnUpLenderReviewProfile | LenderReviewLoadingStatus;
  lenderRepresentation?: LenderRepresentation;
}

export interface LenderRepresentation {
  id: string;
  fullName: string;
  nmls: string;
  logo: string;
  licensedStates?: string[];
  yearFounded?: number;
  fundedAmountLastYear?: string;
}

export const lenderRepresentationsConverter = t.array(
  t.shape<LenderRepresentation>({
    id: t.string,
    fullName: t.string,
    nmls: t.string,
    logo: t.string,
    licensedStates: t.optional(t.array(t.string)),
    yearFounded: t.optional(t.number),
    fundedAmountLastYear: t.optional(t.string)
  })
);
