import { OwnUpLargeBodyMedium } from '@rategravity/own-up-component-library';
import React from 'react';
import { ReviewStars } from '../review-stars';
import { Rating } from './elements';

type RatingBadgeProps = React.PropsWithChildren<{
  score: string;
  stars: number;
}>;

export const RatingBadge = ({ score, stars, children }: RatingBadgeProps) => (
  <Rating>
    <OwnUpLargeBodyMedium variant="body1">{score}</OwnUpLargeBodyMedium>
    <ReviewStars value={stars} />
    {children}
  </Rating>
);
