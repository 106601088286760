import { standardizeResponse } from '../../../modules/standardize-response';
import {
  lenderRepresentationsConverter,
  ownUpLenderReviewProfileConverter,
  zillowLenderReviewProfileConverter
} from './types';

// zillow reviews
export const fetchLenderReview = async (lenderId: string) =>
  standardizeResponse(
    await fetch(`/api/caas/lender/${lenderId}/reviews`, {
      method: 'GET'
    }),
    zillowLenderReviewProfileConverter
  );

// OU reviews
export const fetchOULenderReview = async (lenderId: string) =>
  standardizeResponse(
    await fetch(`/api/caas/lender/${lenderId}/own-up/reviews`, {
      method: 'GET'
    }),
    ownUpLenderReviewProfileConverter
  );

export const fetchLenderRepresentations = async (lenderIds: string[]) => {
  return await standardizeResponse(
    await fetch(`/api/caas/lender/representations?lenderIds=${lenderIds.join(',')}`, {
      method: 'GET'
    }),
    lenderRepresentationsConverter
  );
};
