import {
  MOSS_70,
  OwnUpUnderlineLink,
  PropsWithTheme,
  WALDEN_100
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const ReviewLink = styled(OwnUpUnderlineLink)`
  margin-top: 4px;
  color: ${WALDEN_100};
  width: fit-content;
`;

export const Rating = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(18.5)}px;
  width: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(18.5)}px;
  background-color: ${MOSS_70};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  border-radius: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
`;

export const LogoImage = styled.img`
  width: auto;
  height: 20px;
`;

export const LogoWrapper = styled.div`
  & svg {
    height: 20px;
    width: 80px;
  }
  margin-top: 12px;
`;
