import {
  FOG_100,
  MOSS_70,
  OwnUpDetail,
  PropsWithTheme,
  SLATE_100
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const Divider = styled.hr`
  margin-block-start: 0;
  margin-block-end: 0;
  border: 0;
  border-top: 1px solid ${FOG_100};
  width: 100%;
  margin-top: 1px; // Offset the border.

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const Rating = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(18.5)}px;
  width: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(18.5)}px;
  background-color: ${MOSS_70};
  border-radius: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
`;

export const RatingContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  column-gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;

export const RatingFinePrint = styled(OwnUpDetail)`
  color: ${SLATE_100};
`;
