import {
  MOSS_70,
  OwnUpOverline,
  OwnUpSmallBody,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { LenderRepresentation } from '../../reviews/types';
import { LenderInformationContainer } from '../elements';

export type LenderStatsProps = Pick<
  LenderRepresentation,
  'fundedAmountLastYear' | 'licensedStates' | 'yearFounded'
>;

const LenderStatsCopy = styled(OwnUpSmallBody)`
  background-color: ${MOSS_70};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
`;

export const LenderStats = ({
  fundedAmountLastYear,
  licensedStates,
  yearFounded
}: LenderStatsProps) => {
  const showFundedAmount = useMemo(
    () => typeof fundedAmountLastYear !== 'undefined' && fundedAmountLastYear[0] !== '0',
    [fundedAmountLastYear]
  );

  const activeYears = useMemo(
    () => (yearFounded ? new Date().getFullYear() - yearFounded : 0),
    [yearFounded]
  );
  const showActiveYears = activeYears > 1;

  const showLendingStates = useMemo(
    () => !!licensedStates && licensedStates.length > 0,
    [licensedStates]
  );

  return (
    <LenderInformationContainer>
      <OwnUpOverline>LENDER STATS</OwnUpOverline>
      {showFundedAmount && (
        <LenderStatsCopy variant="body1">
          <strong>{fundedAmountLastYear}</strong> total loans funded last year
        </LenderStatsCopy>
      )}
      {showActiveYears && (
        <LenderStatsCopy variant="body1">
          <strong>{activeYears} years</strong> in industry
        </LenderStatsCopy>
      )}
      {showLendingStates && (
        <LenderStatsCopy variant="body1">
          Lends in{' '}
          <strong>
            {licensedStates!.length > 1
              ? `${licensedStates!.length} states`
              : `${licensedStates!.length} state`}
          </strong>
        </LenderStatsCopy>
      )}
    </LenderInformationContainer>
  );
};
