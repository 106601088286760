import { useTrackingInstance } from '@rategravity/1pt-lib';
import { OwnUpDetailMedium } from '@rategravity/own-up-component-library';
import React, { useCallback } from 'react';
import zillowLogo from '../../../../images/csma/zillow-logo.png';
import { ZillowLenderReviewProfile } from '../types';
import { LogoImage, ReviewLink } from './elements';
import { RatingBadge } from './rating-badge';

type ZillowReviewProps = {
  lenderName: string;
  zillowLenderReviewProfile: ZillowLenderReviewProfile;
};

export const ZillowReview = ({
  lenderName,
  zillowLenderReviewProfile: { rating, profileURL, totalReviews }
}: ZillowReviewProps) => {
  const trackingInstance = useTrackingInstance();

  const handleZillowClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();

      const eventName = 'csma_zillow_review_clicked_for';
      trackingInstance.track(eventName, { lenderName });

      window.open(profileURL); // Defaults to opening in a new window
    },
    [trackingInstance, profileURL, lenderName]
  );

  return (
    <RatingBadge score={rating.toFixed(1)} stars={rating}>
      <ReviewLink link={profileURL} target="_blank" onClick={handleZillowClick}>
        <OwnUpDetailMedium variant="body1">{totalReviews} reviews</OwnUpDetailMedium>
        <LogoImage src={zillowLogo} alt="Real Estate on Zillow" />
      </ReviewLink>
    </RatingBadge>
  );
};
