import React from 'react';
import { Lender } from '../types';
import { OwnUpReview } from './own-up-review';
import { ZillowReview } from './zillow-review';

export type SummaryProps = {
  lenderName: string;
  ownUpLenderReviewProfile: Lender['ownUpLenderReviewProfile'];
  zillowLenderReviewProfile: Lender['zillowLenderReviewProfile'];
  showOwnUpRating: boolean;
  showZillowRating: boolean;
};

export const Summary = ({
  lenderName,
  ownUpLenderReviewProfile,
  zillowLenderReviewProfile,
  showZillowRating,
  showOwnUpRating
}: SummaryProps) => {
  const ownUpReview = 'reviews' in ownUpLenderReviewProfile ? ownUpLenderReviewProfile : null;
  const zillowReview = 'rating' in zillowLenderReviewProfile ? zillowLenderReviewProfile : null;

  return (
    <React.Fragment>
      {ownUpReview && showOwnUpRating && (
        <OwnUpReview lenderName={lenderName} ownUpLenderReviewProfile={ownUpReview} />
      )}
      {zillowReview && showZillowRating && (
        <ZillowReview lenderName={lenderName} zillowLenderReviewProfile={zillowReview} />
      )}
    </React.Fragment>
  );
};
