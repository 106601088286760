import { OwnUpOverline } from '@rategravity/own-up-component-library';
import React from 'react';
import { LENDER_SPOTLIGHT_REVIEW_MAP } from '../../../../data/content/csma/lender-spotlight-reviews';
import { Summary } from '../../reviews/summary';
import { Lender } from '../../reviews/types';
import { LenderInformationContainer } from '../elements';
import { Divider, RatingContainer, RatingFinePrint } from './elements';
import { SpotlightReviewComponent } from './spotlight-review';

const SHOW_RATING_THRESHOLD = 3;

export type SuccessStoriesProps = {
  lenderId: string;
  lenderName: string;
  ownUpLenderReviewProfile: Lender['ownUpLenderReviewProfile'];
  zillowLenderReviewProfile: Lender['zillowLenderReviewProfile'];
};

export const SuccessStories = ({
  lenderId,
  lenderName,
  ownUpLenderReviewProfile,
  zillowLenderReviewProfile
}: SuccessStoriesProps) => {
  const zillowRating = 'rating' in zillowLenderReviewProfile ? zillowLenderReviewProfile.rating : 0;
  const ownUpRating =
    'reviews' in ownUpLenderReviewProfile ? ownUpLenderReviewProfile.averageScore : 0;

  const showZillowRating = zillowRating > SHOW_RATING_THRESHOLD && zillowRating > ownUpRating;
  const showOwnUpRating = ownUpRating > SHOW_RATING_THRESHOLD && ownUpRating > zillowRating;
  const showRatings = showZillowRating || showOwnUpRating;

  const review = LENDER_SPOTLIGHT_REVIEW_MAP[lenderId];
  const showSuccessStories = !!review || showRatings;

  return showSuccessStories ? (
    <React.Fragment>
      <Divider />
      <LenderInformationContainer>
        <OwnUpOverline>SUCCESS STORIES</OwnUpOverline>
        {!!review && <SpotlightReviewComponent {...review} />}
        {showRatings ? (
          <RatingContainer>
            <Summary
              lenderName={lenderName}
              ownUpLenderReviewProfile={ownUpLenderReviewProfile}
              zillowLenderReviewProfile={zillowLenderReviewProfile}
              showZillowRating={showZillowRating}
              showOwnUpRating={showOwnUpRating}
            />
            <RatingFinePrint>
              All reviews are based on {showZillowRating ? 'Zillow' : 'OwnUp'} customer experiences.
            </RatingFinePrint>
          </RatingContainer>
        ) : null}
      </LenderInformationContainer>
    </React.Fragment>
  ) : null;
};
