import { OwnUpBodyMedium, PropsWithTheme } from '@rategravity/own-up-component-library';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  LENDER_SPOTLIGHT_REVIEW_MAP,
  SpotlightReview
} from '../../../../../data/content/csma/lender-spotlight-reviews';
import { DetailCopy } from '../../elements';

const SpotlightReviewContainer = styled.div`
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;

export interface SpotlightReviewProps extends SpotlightReview {
  // ... no additional props
}

/**
 * Display a review with a signature based on the user and where the review was
 *   sourced from.
 */
export const SpotlightReviewComponent = ({ reviewer, source, review }: SpotlightReviewProps) => {
  const signature = useMemo(
    () => `${reviewer ? `${reviewer},` : `Anonymous`} ${source} customer`,
    [reviewer, source]
  );
  return (
    <SpotlightReviewContainer>
      <OwnUpBodyMedium variant="body1">&ldquo;{review}&rdquo;</OwnUpBodyMedium>
      <DetailCopy>{signature}</DetailCopy>
    </SpotlightReviewContainer>
  );
};

export interface LenderSpotlightReviewProps {
  /**
   * ID of lender to pull review for
   */
  lenderId: string;
}

/**
 * Display a spotlight review for the given lender. If we don't have one
 *   it will render nothing.
 */
export const LenderSpotlightReview = ({ lenderId }: LenderSpotlightReviewProps) => {
  const review = LENDER_SPOTLIGHT_REVIEW_MAP[lenderId];
  if (review) {
    return <SpotlightReviewComponent {...review} />;
  }
  return <React.Fragment />;
};
