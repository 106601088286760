import { ALOE_100, FOG_20, FOG_40 } from '@rategravity/own-up-component-library';
import { useLocation } from '@reach/router';
import { parse } from 'querystring';
import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Ankle } from '../components/csma/ankle';
import { FindAMortgageFaster } from '../components/csma/find-mortgage-faster';
import { HeroNonConcierge } from '../components/csma/hero';
import { RdcLandingPageLayout } from '../components/csma/layout';
import { RdcSection } from '../components/csma/layout/elements';
import { LenderCard } from '../components/csma/lender-card';
import { LoadingLogo } from '../components/csma/loading';
import { store } from '../redux/csma';
import { lenderDataSelector } from '../redux/csma/selector';
import { csmaPageLoaded } from '../redux/csma/slice';

const RealtorCsmaNcComponent = () => {
  const location = useLocation();
  const search = location.search.substring(1);
  const { lender_id: lenderId } = parse(search) as Record<string, string>;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(csmaPageLoaded({ lenderId }));
  }, [lenderId, dispatch]);

  const {
    loadingLenderData,
    lenderData: { zillowLenderReviewProfile, ownUpLenderReviewProfile }
  } = useSelector(lenderDataSelector);

  const currentlyLoading =
    loadingLenderData ||
    ('loadingStatus' in zillowLenderReviewProfile &&
      zillowLenderReviewProfile.loadingStatus === 'pending') ||
    ('loadingStatus' in ownUpLenderReviewProfile &&
      ownUpLenderReviewProfile.loadingStatus === 'pending');

  return currentlyLoading ? (
    <LoadingLogo />
  ) : (
    <RdcLandingPageLayout>
      <RdcSection backgroundColor={ALOE_100}>
        <HeroNonConcierge />
      </RdcSection>
      <RdcSection
        style={{
          background: `linear-gradient(to bottom, ${ALOE_100} 25%, ${FOG_20} 25% 100%)`
        }}
      >
        <LenderCard />
      </RdcSection>
      <RdcSection backgroundColor={FOG_20}>
        <FindAMortgageFaster />
      </RdcSection>
      <RdcSection backgroundColor={FOG_40}>
        <Ankle />
      </RdcSection>
    </RdcLandingPageLayout>
  );
};

export const RealtorCsmaNc = () => (
  <Provider store={store}>
    <RealtorCsmaNcComponent />
  </Provider>
);

export default RealtorCsmaNc;
