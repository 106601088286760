import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LenderRepresentation,
  OwnUpLenderReviewProfile,
  ZillowLenderReviewProfile
} from '../../components/csma/reviews/types';
import { LendersState } from './state';

const initialState: LendersState = {
  lenderData: {
    zillowLenderReviewProfile: { loadingStatus: 'pending' },
    ownUpLenderReviewProfile: { loadingStatus: 'pending' }
  },
  loadingLenderData: true
};

export const lenderSlice = createSlice({
  name: 'lender',
  initialState,
  reducers: {
    csmaPageLoaded: (state, { payload: { lenderId } }: PayloadAction<{ lenderId: string }>) => {
      state = {
        ...state,
        lenderId: lenderId
      };
    },
    fetchZillowLenderReviewProfileFailed: (state) => {
      state.lenderData = {
        ...state.lenderData,
        zillowLenderReviewProfile: {
          loadingStatus: 'failed'
        }
      };
    },
    fetchZillowLenderReviewProfileNotFound: (state) => {
      state.lenderData = {
        ...state.lenderData,
        zillowLenderReviewProfile: {
          loadingStatus: 'lenderId_not_found'
        }
      };
    },
    fetchZillowLenderReviewProfileSucceeded: (
      state,
      {
        payload: { zillowLenderReviewProfile }
      }: PayloadAction<{ zillowLenderReviewProfile: ZillowLenderReviewProfile }>
    ) => {
      state.lenderData = {
        ...state.lenderData,
        zillowLenderReviewProfile: {
          ...zillowLenderReviewProfile,
          loadingStatus: undefined
        }
      };
    },
    fetchOwnUpLenderReviewProfileFailed: (state) => {
      state.lenderData = {
        ...state.lenderData,
        ownUpLenderReviewProfile: {
          loadingStatus: 'failed'
        }
      };
    },
    fetchOwnUpLenderReviewProfileNotFound: (state) => {
      state.lenderData = {
        ...state.lenderData,
        ownUpLenderReviewProfile: {
          loadingStatus: 'lenderId_not_found'
        }
      };
    },
    fetchOwnUpLenderReviewProfileSucceeded: (
      state,
      {
        payload: { ownUpLenderReviewProfile }
      }: PayloadAction<{ ownUpLenderReviewProfile: OwnUpLenderReviewProfile }>
    ) => {
      state.lenderData = {
        ...state.lenderData,
        ownUpLenderReviewProfile: {
          ...ownUpLenderReviewProfile,
          loadingStatus: undefined
        }
      };
    },
    fetchLenderRepresentations: (state) => {
      state.loadingLenderData = true;
    },
    fetchLenderRepresentationsFailed: (state) => {
      state.loadingLenderData = false;
    },
    fetchLenderRepresentationsSucceeded: (
      state,
      { payload: { lenders } }: PayloadAction<{ lenders: LenderRepresentation[] }>
    ) => {
      state.lenderData = {
        ...state.lenderData,
        lenderRepresentation: lenders[0]
      };

      state.loadingLenderData = false;
    }
  }
});

export const {
  csmaPageLoaded,
  fetchZillowLenderReviewProfileFailed,
  fetchZillowLenderReviewProfileNotFound,
  fetchZillowLenderReviewProfileSucceeded,
  fetchOwnUpLenderReviewProfileFailed,
  fetchOwnUpLenderReviewProfileNotFound,
  fetchOwnUpLenderReviewProfileSucceeded,
  fetchLenderRepresentations,
  fetchLenderRepresentationsFailed,
  fetchLenderRepresentationsSucceeded
} = lenderSlice.actions;

export const reducer = lenderSlice.reducer;
