import Lottie from 'lottie-react';
import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/extensions
import ownUpLoading from './ownup_loading.json';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
`;

export const LoadingLogo = () => (
  <LoadingContainer>
    <Lottie animationData={ownUpLoading} loop autoplay />
  </LoadingContainer>
);
