/**
 * Append the input suffix to the input file path. For example,
 *  `https://my.ownup.com/onboarding/hello.jpg` with the suffix `_world`
 *   would return `https://my.ownup.com/onboarding/hello_world.jpg`
 *
 * @param path - File path to append
 * @param suffix - Suffix to append
 * @returns path with suffix added before extension
 */
export const addFileSuffix = (path: string, suffix: string) => {
  return path.replace(/(\.[^.]+$)/, `${suffix}$1`);
};
