export interface SpotlightReview {
  /**
   * Text content of the review
   */
  review: string;
  /**
   * Name of the person who sent this review. Can be empty, in which
   *   case we should treat it as an "anonymous" user.
   */
  reviewer?: string;
  /**
   * Where this review was sourced from.
   */
  source: 'Own Up' | 'Zillow';
}

/**
 * Mapping of Lender Ids to {@see LenderSpotlightReview} objects.
 *
 * Sourced from {@link https://www.notion.so/Spotlight-Reviews-ded77e5a4b114b589afc1927919fc9e5}
 */
export const LENDER_SPOTLIGHT_REVIEW_MAP: Record<string, SpotlightReview> = {
  filoMortgage: {
    review:
      'This company knows how to get things done fast! I was ready to close in only 15 days, and we would have been ready sooner if there wasn’t a delay on the seller’s side. They walked me through each step and the process flowed without much effort on my part. Thank you again to the entire Filo Mortgage team!',
    source: 'Zillow',
    reviewer: 'Sierra B.'
  },
  mcgloneMortgage: {
    review:
      'McGlone not only had the one of the best rates and fees in the market, but great industry knowledge and customer service, to boot! I can say this with confidence since I started this process with 4 other companies to compare. Very seamless process.',
    source: 'Zillow',
    reviewer: 'Samuel'
  },
  njLenders: {
    review:
      'They were on top of everything and were extremely responsive at all times day or night. Their app/site also made the lending process so easy to navigate.',
    source: 'Own Up',
    reviewer: 'Tirzah C.'
  },
  onlineCentral: {
    review:
      'The team is super easy to work with, very efficient. The easiest mortgage process we have done in the last 3 homes we purchased. We plan to work with them again when interest rates get better! I enjoy the use of the secure portal because it saves time.',
    source: 'Zillow',
    reviewer: 'Evelyn D.'
  },
  success: {
    review:
      'Our lender was an incredible resource for questions and such a huge support through the entire buying process.',
    source: 'Own Up',
    reviewer: 'Lauren F.'
  }
};
